<template>
  <div>
    <div>
      <h2 class="diviser m-b-lg column">
        <div>Definições para o financeiro</div>
      </h2>
      <div class="m-t">
        <leilao-contas v-model="leilao.config.extra.contas" />
      </div>
    </div>
    <h2 class="diviser m-t-lg m-b-lg">
      Templates específicos para este leilão
    </h2>
    <e-row mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Cobrança de Arremate:"
        >
          <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.cobranca-arremate"
                            v-model="leilao.config.templates.templateCobranca"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Aviso de Condicional:"
        >
          <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lote-condicional"
                            v-model="leilao.config.templates.lanceCodicional"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Aviso de Baixa Oferta:"
        >
          <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lote-condicional"
                            v-model="leilao.config.templates.baixaOferta"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Aviso de Lance:"
        >
          <documento-select @abrirDoc="abrirDoc" code="comunicacao.arrematante.lance-superado"
                            v-model="leilao.config.templates.lanceSuperado"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Nota de Arrematação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-notas-arrematacao"
                            v-model="leilao.config.templates.nota"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Fatura de Arrematação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-recibos-pagamento-lote"
                            v-model="leilao.config.templates.fatura"/>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Recibo de Arremate:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-recibos"
                            v-model="leilao.config.templates.recibo"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Termo de Habilitação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-habilitacao"
                            v-model="leilao.config.templates.termoHabilitacao"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="E-mail de confirmação de Habilitação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-termos"
                            v-model="leilao.config.templates.confirmacaoHabilitacao"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Diário de Leilão:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-diario"
                            v-model="leilao.config.templates.diario"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Certidão de Publicação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-certidao-publicacao"
                            v-model="leilao.config.templates.certidaoPublicacao"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Termo de Alienação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-termo-alienacao"
                            v-model="leilao.config.templates.termoAlienacao"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Auto/Certidão Positivo(a) de Arrematação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-positivo"
                            v-model="leilao.config.templates.autoPositivo"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Auto Negativo de Arrematação:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-negativo"
                            v-model="leilao.config.templates.autoNegativo"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Auto Negativo 2o Leilão:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-auto-negativo"
                            v-model="leilao.config.templates.autoNegativo2"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <e-row class="m-t" mr>
      <e-col>
        <erp-s-field
            view="tl"
            label="Edital:"
        >
          <documento-select @abrirDoc="abrirDoc" code="documentos-leilao-edital"
                            v-model="leilao.config.templates.edital"/>
        </erp-s-field>
      </e-col>
    </e-row>

    <!--    <div class="m-t-lg">
          <h2 class="diviser m-b-lg column">
            <div>Definições avançadas das configurações do leilão</div>
            <p>Atenção: Somente use se tiver conhecimento para a operação.</p>
          </h2>
          <div class="m-t">
            <textarea v-model="leilao.config"></textarea>
          </div>
        </div>-->
    <div class="docs-main">
      <documento ref="docApp"/>
    </div>
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DocumentoSelect from "@/components/documentos/components/include/DocumentoSelect"
import {ErpSField} from "uloc-vue-plugin-erp"
import Documento from "components/documentos/components/Documento"
import LeilaoContas from "components/leiloes/components/include/Contas"

export default {
  name: "Automacao",
  components: {
    LeilaoContas,
    Documento,
    DocumentoSelect,
    ECol,
    ERow,
    ErpSField
  },
  props: ['leilao'],
  beforeMount() {
    if (!this.leilao.config) {
      this.leilao.config = {}
    }
    if (!this.leilao.config.templates) {
      this.leilao.config.templates = {}
    }
    if (!this.leilao.config.extra) {
      this.leilao.config.extra = {
        contas: null
      }
    }
  },
  methods: {
    abrirDoc(doc, e) {
      this.$refs.docApp.show(doc.id)
    }
  }
}
</script>
