<template>
  <div class="row">
    <div class="col m-r pos-rlt" style="width: 50%">
      <div class="font-11 m-b-xs">Lista de comitentes disponíveis para escolha:</div>
      <select v-model="left" class="w-full wrapper-xs" style="height: 200px; overflow: auto" multiple>
        <option v-for="comitente in leftValue" :value="comitente.value">{{comitente.label}}</option>
      </select>
      <div class="set-options">
        <div>
          <div>
            <e-btn @click="add">
              <u-icon name="chevron-right" type="fa" />
            </e-btn>
          </div>
          <div class="m-t-sm">
            <e-btn @click="remove">
              <u-icon name="chevron-left" type="fa" />
            </e-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="col m-l" style="width: 50%">
      <div class="font-11 m-b-xs">Lista de comitentes que participarão do leilão:</div>
      <select v-model="right" class="w-full wrapper-xs" style="height: 200px; overflow: auto" multiple>
        <option v-for="comitente in rightValue" :value="comitente.value">{{comitente.label}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import {list} from '../../../../domain/comitentes/services'
import MultipleSelect from '../../../../reuse/input/multiple-select'

export default {
  name: 'ComitenteMultipleSelect',
  mixins: [MultipleSelect],
  methods: {
    list: list
  }
}
</script>
