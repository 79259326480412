export default {
  props: {
    value: {required: true}
  },
  data () {
    return {
      loading: false,
      model: [],
      selecteds: this.value,
      left: [],
      right: []
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    selecteds () {
      this.$nextTick(() => {
        this.$emit('input', this.selecteds)
      })
    }
  },
  computed: {
    leftValue () {
      return this.model.filter((a) => {
        return !this.selecteds.find((v) => {
          return v.id === a.id
        })
      })
    },
    rightValue () {
      return this.selecteds.map((obj) => {
        return {
          ...obj,
          label: obj.pessoa.name,
          value: obj.id
        }
      })
    }
  },
  methods: {
    load () {
      this.list(1000, 1, '&sortBy=nome') // TODO: Não pode ter limite ou resolver paginação.
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.pessoa.name,
              value: obj.id
            }
          })

          this.model = list
        })
        .catch((error) => {
          this.loading = false

        })
    },
    add () {
      console.log(this.left)
      this.left.map((v) => {
        console.log('Add', v)
        let find = this.model.find((a) => {
          return a.id === v
        })
        console.log('Finded', find)
        if (find) {
          !this.selecteds.find(f => f === find) && this.selecteds.push(find)
        }
      })
    },
    remove () {
      console.log(this.right)
      this.right.map((v) => {
        console.log(v)
        let find = this.selecteds.find((a) => {
          return a.id === v
        })
        console.log('Finded', find)
        if (find) {
          this.selecteds.splice(this.selecteds.indexOf(find), 1)
        }
      })
    }
  }
}
